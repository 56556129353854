import * as Messages from '../lids';
import { translateLid } from '../i18n';

/**
 * Converts a size in bytes to something human-parsable.
 * We opted to not update the lids here to reflect the actual correct suffix in order
 * to not break other products. The correct prefixes for the 1024 bases are MiB, GiB, TiB, etc.
 *
 * @param  {number}  bytes     the raw size in bytes
 * @param  {boolean} tiny      a preset for use in small spaces
 * @param  {number}  precision the number of decimal places
 * @return {string}  the formatted value
 */
export function sizeFromBytes(bytes: number, tiny: boolean = false, precision: ?number = undefined) {
	const ext = [
		translateLid(Messages.DATA_SIZE_B),
		translateLid(Messages.DATA_SIZE_KB),
		translateLid(Messages.DATA_SIZE_MB),
		translateLid(Messages.DATA_SIZE_GB),
		translateLid(Messages.DATA_SIZE_TB),
		translateLid(Messages.DATA_SIZE_PB),
		translateLid(Messages.DATA_SIZE_EB),
		translateLid(Messages.DATA_SIZE_ZB),
		translateLid(Messages.DATA_SIZE_YB)
	];

	const numExt = ext.length;
	let _bytes = bytes;

	let units = 0;
	for (; (_bytes >= 1024) && (units < numExt - 1); units++) {
		_bytes /= 1024;
	}

	if (_bytes === 0) {
		return '0';
	}

	if (_bytes > 1024 && ext[units] === 'YB') {
		return '>1024' + (!tiny ? ' ' + ext[units] : ext[units][0]);
	}

	let _precision;
	if (typeof precision === 'undefined') {
		_precision = units && !tiny ? 1 : 0;
	} else {
		_precision = precision;
	}

	let displayUnit;
	if (tiny) {
		displayUnit = ext[units][0];
	} else {
		displayUnit = ' ' + ext[units];
	}

	return Number(_bytes).toFixed(_precision) + displayUnit;
}

/**
 * Converts a size in bytes to something human-parsable using SI units (1000 base).
 *
 * @param  {number}  bytes     the raw size in bytes
 * @param  {boolean} tiny      a preset for use in small spaces
 * @param  {number}  precision the number of decimal places
 * @return {string}  the formatted value
 */
export function sizeFromBytesSI(bytes: number, tiny: boolean = false, precision: ?number = undefined) {
	const ext = [
		translateLid(Messages.DATA_SIZE_B),
		translateLid(Messages.DATA_SIZE_KB),
		translateLid(Messages.DATA_SIZE_MB),
		translateLid(Messages.DATA_SIZE_GB),
		translateLid(Messages.DATA_SIZE_TB),
		translateLid(Messages.DATA_SIZE_PB),
		translateLid(Messages.DATA_SIZE_EB),
		translateLid(Messages.DATA_SIZE_ZB),
		translateLid(Messages.DATA_SIZE_YB)
	];

	const numExt = ext.length;
	let _bytes = bytes;

	let units = 0;
	for (; (_bytes >= 1000) && (units < numExt - 1); units++) {
		_bytes /= 1000;
	}

	if (_bytes === 0) {
		return '0';
	}

	if (_bytes > 1000 && ext[units] === 'YB') {
		return '>1000' + (!tiny ? ' ' + ext[units] : ext[units][0]);
	}

	let _precision;
	if (typeof precision === 'undefined') {
		_precision = units && !tiny ? 1 : 0;
	} else {
		_precision = precision;
	}

	let displayUnit;
	if (tiny) {
		displayUnit = ext[units][0];
	} else {
		displayUnit = ' ' + ext[units];
	}

	return Number(_bytes).toFixed(_precision) + displayUnit;
}

/**
 * Dupe of PHP's number_format function.
 *
 * @param  {number} number       the number to format
 * @param  {number} decimals     how many decimal places
 * @param  {string} decPoint     defaults to .
 * @param  {string} thousandsSep defaults to ,
 * @return {string} the formatted number
 */
export function numberFormat(
	number: number,
	decimals: number = 0,
	decPoint: string = '.',
	thousandsSep: string = ','
) {
	const _number = (number + '')
		.replace(/[^0-9+\-Ee.]/g, '');
	const n = !isFinite(+_number) ? 0 : +number;
	const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
	let s = '';
	const toFixedFix = (_n, _prec) => {
		const k = Math.pow(10, _prec);
		return '' + (Math.round(_n * k) / k).toFixed(prec);
	};
	// Fix for IE parseFloat(0.55).toFixed(0) = 0;
	s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
	if (s[0].length > 3) {
		s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, thousandsSep);
	}
	if ((s[1] || '')
		.length < prec) {
		s[1] = s[1] || '';
		s[1] += new Array((prec - s[1].length) + 1)
		.join('0');
	}
	return s.join(decPoint);
}

/**
 * Converts the first letter to uppercase.
 *
 * @param  {string} str the string to make title case
 * @return {string} the converted string
 */
export function toTitleCase(str: string) {
	return str.charAt(0).toUpperCase() + str.substr(1);
}

const Format = {
	sizeFromBytes,
	numberFormat,
	toTitleCase,
	sizeFromBytesSI,
};

export default Format;
